<template>
    <li>
        <b-field addons>
            <b-input placeholder="Search..."
                     type="search"
                     icon="search"
                     v-model="query"
                     @keyup.enter="action"
                     @keyup.native.enter="action"
                     expanded
            ></b-input>
            <p class="control">
                <button class="button is-primary" @click="action"><b-icon icon="search"/></button>
            </p>
        </b-field>
    </li>
</template>

<script>
    export default {
        name: 'SearchListItem',
        data: function() {
            return {
                query: ''
            }
        },
        methods: {
            action: function() {
                this.$emit('action', this.query);
                // this.query = '';
            }
        }
    }
</script>

<style lang="scss" scoped>

    $color: #fafafa;

    li {
        background-color: $color !important;
        color: white;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        padding: 15px 15px 15px 10px !important;
        border-bottom: 1px solid $color !important;

        cursor: auto !important;

        .title {
            font-size: 15px;
            font-weight: bold;
        }

        &:hover {
            background-color: $color !important;
        }

        .mh-btn {
            background-color: lighten(#28547F, 10);
            border: lighten(#28547F, 10);

            &:hover {
                background-color: #28547F;
            }
        }
    }
</style>
